<template>
    <div class="container-main">
        <header style="margin-bottom:200px">
            <nav>
                <div class="left-side-con">
                    <div class="hidden-icon onhover-show" >
                        <span class="fa fa-bars hiddenicon"  @click="closeDrop"></span>
                    </div>
                   
                    <div class="image-home">
                     <router-link to="/" class="router">
                        <img src="../../assets/image/logo.png" alt="">
                        <h3>TAPIT</h3>
                    </router-link>
                    </div>
                 
                    <div class="hidden-icon onhover-show">
                        <span class="fas fa-ellipsis-v  hiddenicon"></span>
                    </div>
                </div>
                <div class="right-side-con">
                    <div class="main-head-nav">
                        <div class="left-main-icon">
                            <i class="fa fa-bars" @click="closeDrop"></i>
                        </div>
                        <div class="right-main-icon">
                            <span class="grtuser">Hi,{{username}}</span>    <i class="fa fa-bell"></i>
                        </div>

                    </div>
                </div>
            </nav>
        </header>
        <transition name="slide">


    <aside v-show="hidemes">
        <div class="side-main-bar">
            <ul>
                <li class="width:100%;height:80px !important;border:1px solid black" >
               
                </li>
                <li class="width:100%;border:1px solid black;margin-top:40px" >
                <router-link to="/account/dashboard" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Home </span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/user/transaction" style="width:100%;display:flex;justify-content:space-between"  active-class="bd-l">
                 <span class="menu-item">Transaction</span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/user/refer" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Refer & Earn</span>
                <span class="fa fa-sack-dollar icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="services">Services</li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/transfer" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Transfer</span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/single-data" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Data subscription</span>
                <span class="fa fa-wifi icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/single-airtime" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Airtime</span>
                <span class="fa fa-phone icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/bill" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Bill payment</span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/cable" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">cable & Tv</span>
                <span class="fa fa-tv icon-menu"></span>
                   
                </router-link>
                </li>
                 <li class="services">Account</li>
                     <li class="width:100%;border:1px solid black">
                <router-link to="/service/setting" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Account Settings</span>
                <span class="fa fa-phone icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/profile" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Account Profile</span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/logout" style="width:100%;display:flex;justify-content:space-between">
                 <span class="menu-item">Sign out</span>
                <span class="fa fa-sign-out icon-menu"></span>
                   
                </router-link>
                </li>
                
            </ul>
        </div>
    </aside>
    </transition>
          <Sidebar />
        
            <Balance  :balance="balance" :showme="hidemes"/>
        
        
       
       
    </div>
</template>
<script>

import Balance from '../../components/balance2.vue'
import axios from 'axios'
 import moment from 'moment'

export default {
    
    name:'Dashboard-app',
    components:{
       
        Balance
       
       
        
    },
    data() {
        return {
            hidedrop:true,
            token:'',
            balance:0.00,
            accessToken:"",
            letdrops:'',
            hidemes:true
        }
    },
    methods: {
        hideme(){
           
            this.hidedrop = false
        },
        customDrop(){
            this.showDrop = !this.showDrop
            console.log(this.showDrop)
        },
        closeDrop(){
                this.hidemes = !this.hidemes
        }
      
 },
     async mounted(){
          const data  = JSON.parse(localStorage.getItem('user'));
          this.token = data.data.token
          this.username = data.data.data.username
       
        try{
            const user = await axios.get(`${process.env.VUE_APP_BASE_URL}api/getdatils`,{
               headers: {
                    Authorization:"Bearer "+ this.token
                }
            })
         this.balance = user.data.data.balance
       
        
       const data  = JSON.parse(localStorage.getItem('user'));
          this.token = data.data.token
            if(user.data.data.bank==null){
             const auth = await axios.get(`${process.env.VUE_APP_BASE_URL}api/gettoken`,{
                 headers: {
                    Authorization:"Bearer "+ this.token
                }
             })
             this.accessToken = auth.data.data.responseBody.accessToken

                
               
                const current = new Date()
                const bankdata = {
                    accountReference: moment(current).format('YYYYMMDDHHm'),
                    accountName: user.data.data.fname +" "+  user.data.data.lname,
                    customerEmail: user.data.data.email,
                    currencyCode: "NGN",
                    contractCode: "444802260620",
                    customerName:  user.data.data.fname + " "+ user.data.data.lname,
                    getAllAvailableBanks: "false",
                    preferredBanks: ["035"]
                }
                const bank = await axios.post('https://api.monnify.com/api/v2/bank-transfer/reserved-accounts',bankdata,{
                    headers:{
                       Authorization:"Bearer "+ this.accessToken
                    }
                })
              

                if(bank.data.requestSuccessful){
                    const bankp = {
                    accountName: bank.data.responseBody.accounts[0].accountName,
                    bank: bank.data.responseBody.accounts[0].accountNumber,
                    mref:bank.data.responseBody.reservationReference,
                    ref:bank.data.responseBody.accountReference,
                    bankcode: bank.data.responseBody.accounts[0].bankCode,
                    bankname:bank.data.responseBody.accounts[0].bankName,
                }
                 const data  = JSON.parse(localStorage.getItem('user'));
                 this.token = data.data.token
                 console.log(this.token)
                 await axios.post(`${process.env.VUE_APP_BASE_URL}api/updatebank`,bankp,{
                        headers: {
                        Authorization:"Bearer "+ this.token
                    }
                })
             
                }
             
            }
        }
        catch(e){
            console.log(e)
        }

    }
   
    
}
</script>

<style>
    .container-main{
        max-width: 1519.20px;
        margin:0px auto;
       width:100%;
       position:relative;
    }
    main{
      
        width:100%;
    }
     header{
        width:100% !important;
        position:fixed;
        background: #fff;
        box-shadow: 0px 2px 30px 2px rgb(0 0 0 / 10%);
        height: 70px;
        z-index:100000;
    }
    nav{
    
     
      height:100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
    }
    .left-side-con{
        width:250px;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        height: 100%;
        padding: 10px;
    }
    .right-side-con{
        background: #0A1AA8 ;
        height:100%;
        width: calc(100% - 260px);
        display: flex;
        justify-content: space-between;
        flex-direction: row;

    }
     .hidden-icon{
        display: none;
        padding: 10px;
    }
    .image-home img{
        width:36px;
        float: left;
        
    }
    .image-home{
      
      
       width:100%;
        animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
        vertical-align: middle;
        
    }
    h3{
       
        float: left;
       
        animation: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1) 0s normal forwards 1 fadein;
        vertical-align: middle;
        font-size:1.51rem;
        margin: 6px;
    }
   .main-head-nav{
    padding:10px;
    
    width:100%;
    display: flex;
    justify-content: space-between;
   }
   .left-main-icon{
   
    place-items: center;
    align-content: center;
    padding:10px;
   }
   .right-main-icon{
        display: flex;
        justify-content: space-between;
        padding: 10px;
       
   }
   i{
    font-size:1.3rem;
    color:#fff !important
   }
   .grtuser{
    color:#fff;
    font-weight: 800;
    margin-right:20px;
   
   
   }
  @media screen  and (max-width:490px){
    .left-side-con{
        width:100%;
      
        display: flex;
        justify-content: space-between;
    }
    .right-side-con{
        display: none;
    }
    .image-home{
        width:100%;
        justify-content: center;
        display: flex;
    }
    .hidden-icon{
        display: inline;
     
        padding: 10px;

    }
    
    
  }
  
  .hiddenicon{
    font-size:1.3rem;
    margin:10px;
  }
  .fa{
    cursor: pointer;
  }
  aside{
    transition: 300ms ease all;
    backface-visibility: hidden;
    width:256px;
    background: #fff;
    box-shadow: 1px 0px 30px rgb(0 0 0 / 10%);
    padding: 10px;
    margin: top 0px;
    float:left;
    height:100%;
    position:fixed;
    top:50;
    left:0
}
.side-main-bar{
    touch-action: none;
    overflow: hidden !important;
    height: 100%;
    position: relative;
   
   
}
ul{
    font-size: 1.1rem;
    letter-spacing: 0.2px;
    font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
    font-weight: 400;
    overflow-y: hidden;
    margin-top:40px
}
ul li{
    line-height:1.8;
    display:flex;
    justify-content:space-between;
    height:45px
}
.menu-item{
    font-size: 0.9rem;
}
.icon-menu{
    font-size: 0.9rem !important ;
    margin-top:4px
}
li:hover {
    padding-left:20px;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
}
.services{
    font-weight: 700;
    font-size: 1.2rem;
}
.slide-enter-active{
    transition: all 0.3s ease-out;
}
.slide-leave-active{
    transition: all 0.3s ease-out;
}
.slide-enter-from,
.slide-leave-to{
    opacity: 0;
    transform: translate(20px);
}
@media screen and (max-width:996px) {
    aside{
        position:fixed;
        height:100%;
    }
}
@media screen and (max-width:755px) {
     aside{
        position:fixed;
        height:100%;
    }
}
.bd-l{
   border-left:4px solid #0A1AA8;
   padding-top:5px;
 
}
</style>