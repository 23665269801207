<template>
<transition name="slide">


    <aside v-show="hideme">
        <div class="side-main-bar">
            <ul>
                <li class="width:100%;height:80px !important;border:1px solid black" >
               
                </li>
                <li class="width:100%;border:1px solid black;margin-top:40px" >
                <router-link to="/account/dashboard" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Home </span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/user/transaction" style="width:100%;display:flex;justify-content:space-between"  active-class="bd-l">
                 <span class="menu-item">Transaction</span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/user/refer" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Refer & Earn</span>
                <span class="fa fa-sack-dollar icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="services">Services</li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/transfer" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Transfer</span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/single-data" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Data subscription</span>
                <span class="fa fa-wifi icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/single-airtime" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Airtime</span>
                <span class="fa fa-phone icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/bill" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Bill payment</span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/cable" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">cable & Tv</span>
                <span class="fa fa-tv icon-menu"></span>
                   
                </router-link>
                </li>
                 <li class="services">Account</li>
                     <li class="width:100%;border:1px solid black">
                <router-link to="/service/setting" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Account Settings</span>
                <span class="fa fa-phone icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/profile" style="width:100%;display:flex;justify-content:space-between" active-class="bd-l">
                 <span class="menu-item">Account Profile</span>
                <span class="fa fa-home icon-menu"></span>
                   
                </router-link>
                </li>
                <li class="width:100%;border:1px solid black">
                <router-link to="/service/logout" style="width:100%;display:flex;justify-content:space-between">
                 <span class="menu-item">Sign out</span>
                <span class="fa fa-sign-out icon-menu"></span>
                   
                </router-link>
                </li>
                
            </ul>
        </div>
    </aside>
    </transition>
</template>
<script>
export default {
    name:'Side-bar',
    props:[
        'hideme'
    ]
}
</script>
<style>
aside{
    transition: 300ms ease all;
    backface-visibility: hidden;
    width:256px;
    background: #fff;
    box-shadow: 1px 0px 30px rgb(0 0 0 / 10%);
    padding: 10px;
    margin: top 0px;
    float:left;
    height:100%;
    position:fixed;
    top:50;
    left:0
}
.side-main-bar{
    touch-action: none;
    overflow: hidden !important;
    height: 100%;
    position: relative;
   
   
}
ul{
    font-size: 1.1rem;
    letter-spacing: 0.2px;
    font-family: "Quicksand", Georgia, "Times New Roman", Times, serif;
    font-weight: 400;
    overflow-y: hidden;
    margin-top:40px
}
ul li{
    line-height:1.8;
    display:flex;
    justify-content:space-between;
    height:45px
}
.menu-item{
    font-size: 0.9rem;
}
.icon-menu{
    font-size: 0.9rem !important ;
    margin-top:4px
}
li:hover {
    padding-left:20px;
    transition-duration: 0.5s;
    transition-delay: 0.5s;
}
.services{
    font-weight: 700;
    font-size: 1.2rem;
}
.slide-enter-active{
    transition: all 0.3s ease-out;
}
.slide-leave-active{
    transition: all 0.3s ease-out;
}
.slide-enter-from,
.slide-leave-to{
    opacity: 0;
    transform: translate(20px);
}
@media screen and (max-width:996px) {
    aside{
        position:fixed;
        height:100%;
    }
}
@media screen and (max-width:755px) {
     aside{
        position:fixed;
        height:100%;
    }
}
.bd-l{
   border-left:4px solid #0A1AA8;
   padding-top:5px;
 
}
</style>