import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../views/account/dashboard.vue'
import Register from '../views/panel/register.vue'
import Login from '../views/panel/login.vue'
import Verify from '../views/panel/verify.vue'
import Transfer from '../views/service/transfer.vue'
import Airtime from '../views/service/airtime.vue'
import Data from '../views/service/data.vue'
import SingleData from '../views/service/single-data.vue'

import Single from '../views/service/single-airtime.vue'
import Deposit from '../views/fund/deposit.vue'
import Account from '../views/fund/account.vue'
import Bill from '../views/service/bill.vue'
import Cable from '../views/service/cable.vue'
import Payment from '../views/makepayment/payment.vue'
import Payment2 from '../views/makepayment/payment2.vue'
import Payment3 from '../views/makepayment/payment3.vue'



const routes = [{
        path: '/account/dashboard',
        name: 'TAPIT | Dashboard',
        component: Dashboard
    },
    {
        path: '/panel/register',
        name: 'TAPIT | Register',
        component: Register
    },
    {
        path: '/panel/login',
        name: 'TAPIT | Login',
        component: Login
    },
    {
        path: '/panel/verify',
        name: 'TAPIT | verify',
        component: Verify
    },
    {
        path: '/service/transfer',
        name: 'TAPIT | Transfer',
        component: Transfer
    },
    {
        path: '/service/airtime',
        name: 'TAPIT | Airtime',
        component: Airtime
    },
    {
        path: '/fund/deposit',
        name: 'TAPIT | Deposit Fund',
        component: Deposit 
    },
    {
        path: '/service/single-airtime',
        name: 'TAPIT | Single Recharge',
        component: Single
    },
    {
        path: '/service/single-data',
        name: 'TAPIT | Single Data',
        component: SingleData
    },
    {
        path: '/fund/account',
        name: 'TAPIT | Account',
        component: Account
    },
    {
        path: '/service/data',
        name: 'TAPIT | Data',
        component: Data
    },
    {
        path: '/service/bill',
        name: 'TAPIT | Bill',
        component: Bill
    },
    {
        path: '/service/cable',
        name: 'TAPIT | Cable',
        component: Cable
    },
    {
        path: '/makepayment/payment',
        name: 'TAPIT | Payment',
        component: Payment
    },
    {
        path: '/makepayment/payment2',
        name: 'TAPIT | Payment2',
        component: Payment2
    },
    {
        path: '/makepayment/payment3',
        name: 'TAPIT | Payment3',
        component: Payment3
    },

]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
})



router.beforeEach((to, from, next) => {

    document.title = `${ to.name}`
    next()


    // to and from are both route objects. must call `next`.
})



  
 
export default router