<template>
    <div  :class="showme?'container' : 'secondContainer'">
        <div class="col-xl">
            <div class="bal-col cl-tr bl-ct" :class="showme?'' : 'top'">
                <div class="bal-con">
                    <div class="hl-depo">
                        <div class="main-bal-col">
                            <span class="bal-tp">Your Tap Balance {{showme}}</span> <br>
                             <span  class="bal-tp" style="font-weight:bold">&#8358;{{Intl.NumberFormat().format(balance)}}</span>
                        </div>
                        <div>
                        <router-link to="/fund/deposit">
                            <button>Fund<span class="fa fa-paper-plane"></span> </button>
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="mlc-dcd">
          
            <div class="shw-lft-col">
                <div class="item-col">
                    <div class="service-col">
                   
                        <div class="ech-ser">
                         <router-link to="/service/transfer">
                            <div class="icon-ch">
                                <span class="fa fa-exchange"></span>
                            </div>
                            <div class="text-ch">
                                Transfer
                            </div>
                            </router-link>
                           <h4></h4>
                        </div>
                        
                         <div class="ech-ser">
                         <router-link to="/service/single-airtime">
                            <div class="icon-ch">
                                <span class="fa fa-phone"></span>
                            </div>
                            <div class="text-ch">
                                Buy Airtime
                            </div>
                            </router-link>
                           <h4></h4>
                        </div>
                         <div class="ech-ser">
                         <router-link to="/service/single-data">
                            <div class="icon-ch">
                                <span class="fa fa-wifi"></span>
                            </div>
                            <div class="text-ch">
                                Buy Data
                            </div>
                            </router-link>
                           <h4></h4>
                        </div>
                        
                    </div>
                    <div class="service-col">
                        <div class="ech-ser">
                         <router-link to="/service/cable">
                            <div class="icon-ch">
                                <span class="fa fa-tv"></span>
                            </div>
                            <div class="text-ch">
                                Cable & Tv
                            </div>
                            </router-link>
                           <h4></h4>
                        </div>
                         <div class="ech-ser">
                         <router-link to="/service/bill">
                            <div class="icon-ch">
                                <span class="fa fa-phone"></span>
                            </div>
                            <div class="text-ch">
                                Bill Payment
                            </div>
                            </router-link>
                           <h4></h4>
                        </div>
                         <div class="ech-ser">
                         <router-link to="/service/refer">
                            <div class="icon-ch">
                                <span class="fa fa-sack-dollar"></span>
                            </div>
                            <div class="text-ch">
                                Refer and Earn
                            </div>
                            </router-link>
                           <h4></h4>
                        </div>
                        
                    </div>
                </div>
            </div>
            
        </div>
            </div>
            <div class="bal-col trc-cl kt-lv" :class="showme?'' : 'top'">
                    <div class="recent-transac" style="min-height:100px;margin-bottom:40px;">
                       <div style="float:left;margin-top:20px">
                        <h2 style="color:#fff">Recent Transaction</h2>
                        <p class="txt-p">Your recent transaction are enlisted here.</p>
                        </div>
                    <div style="float:right;margin-top:20px">
                        <button style="height:30px">View all</button>
                    </div>
                   
                    </div>
                    <div class="table-co">
                    <table style="margin-top:20px;">
                    <tr class="hf-tb" style="margin-bottom:20px">
                    <td>Service</td>
                    <td>Amount</td>
                  
                   
                    <td>Status</td>
                </tr>

                <tr v-for="item in transactions" :key="item.id">
               
                    <td class="mg-tb">Transfer</td>
                    <td>&#8358;{{Intl.NumberFormat().format(item.amount)}}</td>
                   
                   
                   
                    <td>{{item.status}}</td>
              
                </tr>
            </table>
                    </div>
                
            </div>
           
        </div>
     
    </div>
</template>
<script>
import axios from'axios'

export default {
    name:'Balance2-app',
  
    data() {
        return {
            transactions:[]
        }
    },
    
    props:[
        'balance',
        'showme'
    ],
    methods:{
        clickmeCheck(){
            alert('me')
        }
    },
   async mounted(){
            const data  = JSON.parse(localStorage.getItem('user'));
            this.token = data.data.token
            try{
                const transaction = await axios.get(`${process.env.VUE_APP_BASE_URL}api/gettransaction`,{
                    headers:{
                        Authorization:"Bearer "+ this.token
                    }
                })
               
                this.transactions = transaction.data.data
            }
            catch(e){
                e
            }
    }
   
    
}
</script>
<style scoped>
.container{
   float:right;
    padding:10px;
    min-width:calc(100% - 286px);
    margin-top:50px;
  
    
}
.secondContainer{
     
    padding:10px;
    padding:10px;
   
}
.col-xl{
    display: flex;
    justify-content: space-between;

}
.bal-col{
    float: left;
  
    padding:10px;
    width:50%
   
}
.cl-tr{
    min-width:40%
}
.trc-cl{
    min-width:35%
}
.bal-con{
    background: #0A1AA8;
    padding:10px;
    border:1px solid #0A1AA8;
    border-radius:10px ;
    margin-top:30px;
    font-size:1.2rem;
    color:#fff;
     margin-bottom: 40px;
}
.bl-ct{
    width:65%;
}
.item-col{
        
        font-size: 1.2rem;
        background: #fff ;
        border: 1px solid #fff ;
        border-radius: 10px;
        padding:10px;
        box-shadow: 0px 1px 15px 1px rgb(62 57 107 / 7%);
}
    .hl-depo{
        height:60px;
        margin:10px;
        padding:10px;
       
        display: flex;
        justify-content: space-between;
        flex-direction: row;
    }
    .bal-tp{
        font-size: 1rem;
        color:#ccc;
    }
    button{
        padding:5px;
        outline:none;
        color:#0A1AA8;
        font-weight: 600;
    }
    .fa-paper-plane{
        color:#0A1AA8 !important
    }
    .service-col{
        display: flex;
        justify-content: space-between;
        margin:10px;
        padding:10px;
       
    }
    .ech-ser{
        padding:10px;
        border:1px solid #ccc;
        border-radius: 20px;
        min-height:70px;
        width:calc(100% / 2.5);
        margin:10px
    }
    .icon-ch{
        align-content: center;
        display: flex;
       
        place-content: center;
        padding:10px;
        line-height: 2;
        
    }
    .text-ch{
        font-size: 0.9rem;
        text-align: center;
        margin-top:5px
    }
     @media screen  and (max-width:490px){
    .xl-plc{
        display: none;
    }
    .shw-lft-col{
        width:100%
    }
    .text-ch{
        font-size:0.7rem
    }
    
  }
  .recent-transac{
    margin-top:30px;
    font-size:1.2rem;
    background: #0A1AA8;
    color:#fff !important;
    padding: 10px;
    border-radius: 10px !important;
    justify-content:space-between;
    display:flex;

  }
  .recent-transac h2{
    font-size:1rem
  }
  .table-co{
   
    overflow-y:auto ;
    overflow-x: auto;
    padding:10px;
    background:#fff;
    box-shadow: 0px 1px 15px 1px rgb(62 57 107 / 7%);
    max-height:370px;
    border-radius:10px;
    
  }
  table{
   background:#fff;
    font-size:1rem;
    width:100%;
    box-shadow: 0px 1px 15px 1px rgb(62 57 107 / 7%);

 
  }
  tr td{
       border-bottom:1px solid #ccc;
      font-size:1rem;
  }
  .hf-tb{
    background: #ddd;
    padding:5px;
    font-size:1rem
  }
  .mg-tbP{
     padding:10px
  }
   @media screen and (max-width:1100px) {
        .col-xl {
            display: flex;
            justify-content: space-between;
            box-sizing: border-box;
        }
        .bl-ct,.kt-lv{
            width:100%;
            box-sizing: border-box;
        }
    }
  @media screen and (max-width:755px) {
    .col-xl{
      
        display: block;
    }
   .bl-ct,.kt-lv{
    width:100%;
    box-sizing: border-box;
   }
}
.txt-p{
    color:#fff;
    font-size:0.8rem
}
.table-co::-webkit-scrollbar{
    width:2px;

}
.top{
    margin-top:50px
}
 
</style>